import api from "./base-api"
import {v4 as uuidv4} from "uuid";

const clockmakerAnalyticalDataApiV1 = api.injectEndpoints({
    endpoints: (build) => ({
        getConsumablesV1CM: build.query({
            query: (
                data
            ) => {
                return `v1/clockmaker/analytical-data/${data.userId}/consumables?limit=${data.limit}&page=0&from=${data.from}&to=${data.to}`
            },
            transformResponse: (response) => {
                const rows = response.map((row) => ({id: uuidv4(), ...row}));
                return {rows};
            },
        }),
        getEnvironmentsV1CM: build.query({
            query: (
                userId = "",
                limit = 200,
                page = 0
            ) => `v1/clockmaker/analytical-data/${userId}/environments?limit=${limit}&page=${page}`
        }),
        getLevelsV1CM: build.query({
            query: (
                userId = "",
                limit = 200,
                page = 0
            ) => `v1/clockmaker/analytical-data/${userId}/levels?limit=${limit}&page=${page}`
        }),
        getPaymentsV1CM: build.query({
            query: (
                userId = "",
                limit = 200,
                page = 0
            ) => `v1/clockmaker/analytical-data/${userId}/payments?limit=${limit}&page=${page}`
        }),
        getPurchasesV1CM: build.query({
            query: (
                userId = "",
                limit = 200,
                page = 0
            ) => `v1/clockmaker/analytical-data/${userId}/purchases?limit=${limit}&page=${page}`
        }),
        getUserInfoV1CM: build.query({
            query: (
                userId = "",
                limit = 200,
                page = 0
            ) => `v1/clockmaker/analytical-data/${userId}/user-info?limit=${limit}&page=${page}`
        }),
    }),
    overrideExisting: false
})

export const {
    useGetConsumablesV1CMQuery,
    useGetEnvironmentsV1CMQuery,
    useGetUserInfoV1CMQuery,
    useGetLevelsV1CMQuery,
    useGetPaymentsV1CMQuery,
    useGetPurchasesV1CMQuery
} = clockmakerAnalyticalDataApiV1
