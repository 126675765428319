import React, {useEffect, useState} from 'react';
import {Link, useParams, useSearchParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";

import {Box, Card, Grid, Tab, Tabs} from "@mui/material";

import {TabContext, TabPanel} from "@mui/lab";

import {useGetStateV2TOHQuery} from "../../core/api/toh-state-api";


import RawDataEdit from "./ba/RawDataEdit";
import ChargesList from "../Charger/chargesList";
import PaymentsRT from "../Payments/rt";
import Loading from "../../components/Common/Loading";
import RtConsumables from "../Consumables/rt";
import TechInfoRT from "../TechInfo/techInfoRT";
import RTLocationsTable from "./rt/RTLocationsTable";
import InGameListRT from "../InGame/InGameListRT";
import OffersListRT from "./rt/OffersListRT";
import {stringify, parse} from "lossless-json";
import {isEmpty} from "../../core/utils/StringUtils";
import {getDeviceIdAbsentWarning} from "../../core/utils/constants";


function UserStatesRT(props) {

    const {action, tab: tabParam, subtab: subTabParam, game} = useParams()
    const CommonData = useSelector((state) => state.data.CommonData)
    const deviceId = CommonData.deviceId;
    const stateId = CommonData.stateId;
    const deviceIdIsEmpty = isEmpty(deviceId) && isEmpty(stateId);
    const errorMessage = CommonData.errorMessage;

    const link_prefix = '/' + game + '/' + action + '/' + deviceId + '/'
    const tab = tabParam || 'update'
    const subTab = subTabParam || (tab == 'analytics' ? 'payments' : 'consumables');
    const [params] = useSearchParams();
    const vendorIdParam = params.get('vendorId');
    let paramString = `?vendorId=${vendorIdParam}`

    const state = useSelector((state) => state.data.SolitaierState)

    //const state = useState()
    const dispatch = useDispatch();

    const {
        data: obj,
        isLoading,
        isSuccess,
        isError,
        error,
        status: objStatus
    } = useGetStateV2TOHQuery({deviceId, stateId})


    const [tabIndex, setTabIndex] = useState(tab);
    const [subTabIndex, setSubTabIndex] = useState(subTab);

    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
        setSubTabIndex(newTabIndex == 'analytics' ? 'payments' : 'consumables');
    };


    const handleSubTabChange = (event, newTabIndex) => {
        setSubTabIndex(newTabIndex);
    };


    useEffect(() => {

    }, [deviceId, dispatch]);

    let status = ""

    let content = ""
    let panel1 = ""
    let panel2 = ""

    let charges = ""


    if (typeof obj === "object" && obj.hasOwnProperty('data') && objStatus === 'fulfilled') {
        //const data = obj.data
        const raw = parse(atob(obj.value))
        const data = JSON.parse(atob(obj.value))

        if (data.hasOwnProperty('Records'))
            panel2 = <RTLocationsTable locations={data.Records.LocationProfile}/>

        content = <TabContext value={tabIndex}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={tabIndex} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="Update" value="update" component={Link} to={link_prefix + "update" + paramString}/>
                    {/*<Tab label="Locations" value="locations" component={Link} to={link_prefix + "locations" + paramString}/>*/}
                    {/*<Tab label="InGame ItemProfile" value="ingame" component={Link} to={link_prefix + "ingame" + paramString}/>*/}
                    {/*<Tab label="Chest" value="chest" component={Link} to={link_prefix + "chest" + paramString}/>*/}
                    {/*<Tab label="Decor" value="decor" component={Link} to={link_prefix + "decor" + paramString}/>*/}
                    {/*<Tab label="Offers" value="offers" component={Link} to={link_prefix + "offers" + paramString}/>*/}


                    {/*<Tab label="Analytics" value="analytics" component={Link} to={link_prefix + "analytics" + paramString}/>*/}

                </Tabs>
            </Box>

            <TabPanel value="analytics">
                <TabContext value={subTabIndex}>
                    <Box className={"subtabs"}>
                        <Tabs value={subTabIndex} onChange={handleSubTabChange} aria-label="basic tabs example">

                            <Tab label="TechInfo" value="techinfo" component={Link}
                                 to={link_prefix + "analytics/techinfo" + paramString}/>
                            <Tab label="Payments" value="payments" component={Link}
                                 to={link_prefix + "analytics/payments" + paramString}/>
                            <Tab label="Balance items" value="balanceitems" component={Link}
                                 to={link_prefix + "analytics/balanceitems" + paramString}/>

                        </Tabs>


                        <TabPanel value="balanceitems">
                            <RtConsumables deviceId={deviceId}/>
                        </TabPanel>
                        <TabPanel value="payments">
                            <PaymentsRT deviceId={deviceId}/>
                        </TabPanel>
                        <TabPanel value="techinfo">
                            <TechInfoRT deviceId={deviceId}/>
                        </TabPanel>

                    </Box>
                </TabContext>
            </TabPanel>


            <TabPanel value="ingame">
                <TabContext value={subTabIndex}>
                    <Box className={"subtabs"}>
                        <Tabs value={subTabIndex} onChange={handleSubTabChange} aria-label="basic tabs example">
                            <Tab label="Consumables" value="consumables" component={Link}
                                 to={link_prefix + "ingame/consumables"}/>
                            <Tab label="Transient" value="transient" component={Link}
                                 to={link_prefix + "ingame/transient"}/>
                            <Tab label="Unlimited" value="unlimited" component={Link}
                                 to={link_prefix + "ingame/unlimited"}/>
                            <Tab label="Permanent" value="permanent" component={Link}
                                 to={link_prefix + "ingame/permanent"}/>
                        </Tabs>

                        <TabPanel value="consumables">
                            <Card>
                                {data.hasOwnProperty("Records") && data.Records.hasOwnProperty("ItemProfile") &&
                                    <InGameListRT items={data.Records.ItemProfile.Consumables}/>
                                }
                            </Card>
                        </TabPanel>
                        <TabPanel value="transient">
                            <Card>
                                {data.hasOwnProperty("Records") && data.Records.hasOwnProperty("ItemProfile") &&
                                    <InGameListRT guidMapName="Transient" items={data.Records.ItemProfile.Transient}/>
                                }
                            </Card>
                        </TabPanel>
                        <TabPanel value="unlimited">
                            <Card>
                                {data.hasOwnProperty("Records") && data.Records.hasOwnProperty("ItemProfile") &&
                                    <InGameListRT items={data.Records.ItemProfile.Unlimited}/>
                                }
                            </Card>
                        </TabPanel>
                        <TabPanel value="permanent">
                            <Card>
                                {data.hasOwnProperty("Records") && data.Records.hasOwnProperty("ItemProfile") &&
                                    <InGameListRT guidMapName="Permanent" items={data.Records.ItemProfile.Permanent}/>
                                }
                            </Card>
                        </TabPanel>

                    </Box>
                </TabContext>
            </TabPanel>

            <TabPanel value="charges">
                <Card>
                    <ChargesList deviceId={deviceId}/>
                </Card>
            </TabPanel>

            <TabPanel value="locations">
                <Card>
                    {panel2}
                </Card>
            </TabPanel>

            <TabPanel value="update">

                <RawDataEdit data={raw} ></RawDataEdit>
            </TabPanel>

            <TabPanel value="chest">
                <Grid item sm={8}>
                    <Card>


                        {(data.hasOwnProperty("Records") && data.Records.hasOwnProperty('ChestItemProfile')) &&
                            <InGameListRT guidMapName="Transient"
                                          items={data.Records.ChestItemProfile.Items.Consumables}/>
                        }
                    </Card>
                </Grid>
            </TabPanel>
            <TabPanel value="offers">
                <Grid item sm={8}>
                    <Card>

                        {data.hasOwnProperty("Records") && data.Records.hasOwnProperty("OfferProfile") &&
                            <OffersListRT items={data.Records.OfferProfile.Offers}/>
                        }
                    </Card>
                </Grid>
            </TabPanel>
            <TabPanel value="decor">
                <Grid item sm={8}>
                    <Card>

                        {(data.hasOwnProperty("Records") && data.Records.hasOwnProperty('DecorProfile')) &&
                            <InGameListRT items={data.Records.DecorProfile.Items.Consumables}/>
                        }
                    </Card>
                </Grid>
            </TabPanel>
        </TabContext>

    }

    return (
        <>
            <Loading isError={isError} isLoading={isLoading} error={error}>
                <div>{status}</div>
                <div>{content}</div>
            </Loading>


        </>
    );

}

export default UserStatesRT

