import {Button, Checkbox, DialogActions, DialogContent, FormControlLabel, Grid, TextField} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";

const CreateUpdateModal = (props) => {
    return (
        <>
            <DialogTitle>
                {props.charge.id ? "Update charge " + props.charge.name : "Create a new charge"}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            sx={{m: 0}}

                            label="Name"
                            value={props.charge.name}
                            onChange={(e) => props.setCharge({
                                ...props.charge,
                                "name": e.target.value
                            })}
                            margin="normal"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            sx={{m: 0}}
                            label="Value"
                            value={props.charge.value}
                            onChange={(e) => props.setCharge({
                                ...props.charge,
                                "value": e.target.value
                            })}
                            margin="normal"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            sx={{m: 0}}

                            label="Key"
                            value={props.charge.key}
                            onChange={(e) => props.setCharge({
                                ...props.charge,
                                "key": e.target.value
                            })}
                            margin="normal"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            sx={{m: 0}}

                            label="Guidmap"
                            value={props.charge.guidMap}
                            onChange={(e) => props.setCharge({
                                ...props.charge,
                                "guidMap": e.target.value
                            })}
                            margin="normal"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            sx={{m: 0}}

                            label="Key 2"
                            value={props.charge.key2}
                            onChange={(e) => props.setCharge({
                                ...props.charge,
                                "key2": e.target.value
                            })}
                            margin="normal"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            sx={{m: 0}}

                            label="Guidmap 2"
                            value={props.charge.guidMap2}
                            onChange={(e) => props.setCharge({
                                ...props.charge,
                                "guidMap2": e.target.value
                            })}
                            margin="normal"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.charge.amount}
                                    onChange={(e) => props.setCharge({
                                        ...props.charge,
                                        "amount": e.target.checked
                                    })}
                                    name="Amount"
                                    color="primary"
                                />
                            }
                            label="Amount"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.charge.stringArgs}
                                    onChange={(e) => props.setCharge({
                                        ...props.charge,
                                        "stringArgs": e.target.checked
                                    })}
                                    name="stringArgs"
                                    color="primary"
                                />
                            }
                            label="stringArgs"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.charge.endTime}
                                    onChange={(e) => props.setCharge({
                                        ...props.charge,
                                        "endTime": e.target.checked
                                    })}
                                    name="End Time"
                                    color="primary"
                                />
                            }
                            label="End Time"
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button variant="contained"
                        onClick={props.handleChargeAction}
                >
                    Save
                </Button>

                <Button variant="outlined"
                        onClick={props.handleCancel}
                >
                    Cancel
                </Button>
            </DialogActions>


        </>
    );
}
export default CreateUpdateModal;