import React from 'react';
import {useDispatch, useSelector} from "react-redux";

import './styles.scss';
import {AppBar, Box, Button, MenuItem, Toolbar, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {keycloak} from "../../../keycloak-config";
import logoImg from '../../../imgs/logo.png';
import logoStagingImg from '../../../imgs/logo_staging.png';
import {Link, useLocation} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    toolbarStaging: {
        backgroundColor: "#fff",
        background: "linear-gradient(rgba(255,255,255, 0) .9em, rgba(0, 0, 0, .15) 1em) 0 0, linear-gradient(90deg, rgba(255,255,255, 0) .9em, rgba(0, 0, 0, .15) 1em) 0 0;",
        backgroundSize: "1em 1em"
    },
    toolbarProd: {
        backgroundColor: "#ffffff"
    }
}));

const Header = () => {

    const location = useLocation();


    const state = useSelector((state) => state.data.UserSession)
    const mode = useSelector((state) => state.data.SourceMode)
    const classes = useStyles();
    const dispatch = useDispatch();

    const goToProd = () => {
        if (mode.isProd) return false;

        dispatch({type: "MODE_PROD"});
        window.localStorage.setItem('isProd', true);
        document.location.reload()
    }
    const goToStaging = () => {
        if (!mode.isProd) return false;

        dispatch({type: "MODE_STAGING"});
        window.localStorage.setItem('isProd', false);
        document.location.reload()
    }

    let login
    if (state.authenticated) {
        login = <div><span>Hello, {state.user.username}</span> <Button color="primary" variant="outlined"
                                                                       onClick={keycloak.logout}>Log out</Button></div>
    } else {
        login = <Button color="warning" variant="contained" onClick={keycloak.login}>Log in</Button>
    }

    return (
        <Box className="header" sx={{flexGrow: 1}} pb={3}>
            <AppBar position="static" color="default">
                <Toolbar className={mode.isProd ? classes.toolbarProd : classes.toolbarStaging}>
                    <Box sx={{height: '100%', display: 'flex', alignItems: 'center', paddingRight: '10px'}}>
                        <Link to="/">
                            {mode.isProd && <img src={logoImg} alt="Hermes2 Logo" height="40px"
                                                 sx={{maxWidth: '100%', paddingRight: '20px'}}/>}
                            {mode.isStaging && <img src={logoStagingImg} alt="Hermes2 Logo" height="42px"
                                                    sx={{maxWidth: '100%', paddingRight: '20px'}}/>}
                        </Link>
                    </Box>
                    <Typography color="inherit" component="div">


                        <div>Hermes2</div>
                        {state.authenticated &&
                            <div className="switch-mode">
                                <span className={mode.isProd ? "active" : ""} onClick={goToProd}>prod</span>
                                <span className={mode.isStaging ? "active" : ""} onClick={goToStaging}>staging</span>
                            </div>}
                    </Typography>

                    <Box sx={{flexGrow: 1}}/>
                    {state.authenticated &&
                        <Box sx={{display: "flex", alignItems: "center", flexGrow: 1}}>
                            <Box display="flex" gap={2}>
                                <MenuItem
                                    component={Link}
                                    to="/cm"
                                    selected={location.pathname.startsWith("/cm")}
                                >
                                    CM
                                </MenuItem>
                                <MenuItem component={Link} to="/ba"
                                          selected={location.pathname.startsWith("/ba")}
                                >
                                    BA
                                </MenuItem>
                                <MenuItem component={Link} to="/sc"
                                          selected={location.pathname.startsWith("/sc")}
                                >
                                    SC
                                </MenuItem>
                                <MenuItem component={Link} to="/rt"
                                          selected={location.pathname.startsWith("/rt")}
                                >
                                    RT
                                </MenuItem>
                                <MenuItem component={Link} to="/toh"
                                          selected={location.pathname.startsWith("/toh")}
                                >
                                    ToH
                                </MenuItem>
                            </Box>
                        </Box>
                    }
                    <Box sx={{flexGrow: 1}}/>
                    <Box sx={{display: {xs: 'none', md: 'flex'}}}>
                        {login}
                    </Box>

                </Toolbar>
            </AppBar>
        </Box>

    );
}

export default Header;
